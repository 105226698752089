export default {
  methods: {
    filterData(item, queryText) {
      const textId = item.label.toLowerCase()
      const textImei = item.value.toString().toLowerCase()
      const textType = item.type ? item.type.toString().toLowerCase() : ''
      const searchText = queryText.toString().toLowerCase()
      return textId.indexOf(searchText) > -1 || textImei.indexOf(searchText) > -1 || textType.indexOf(searchText) > -1
    },
  },
}
