<template>
  <v-col>
    <v-autocomplete
      v-model="selected"
      @change="select"
      @keyup="handleKeyUp"
      v-bind="$attrs"
      :items="items"
      :item-text="itemText"
      :item-value="itemValue"
      :label="label"
      :search-input.sync="inputText"
      :disabled="disabled"
      placeholder="Почніть вводити текст для пошуку"
      class="my-2"
      :no-filter="noFilter"
      return-object
      hide-no-data
      hide-selected
      multiple
      chips
      deletable-chips
    >
      <template v-slot:item="{ item }">
        <v-list-item-content :class="{ 'red--text': item.status === 3 }">
          <slot name="item" :item="item">{{ item[itemText] }}</slot>
        </v-list-item-content>
      </template>
      <template v-slot:selection="{ item, index }">
        <v-chip class="ma-1" small close @click:close="closeChip(index)" :disabled="disabled">
          <span class="chip-text">
            <slot name="selection" :item="item">{{ item[itemText] }}</slot>
          </span>
        </v-chip>
      </template>
      <template v-slot:append-outer>
        <OkClear :disable-ok="disableOk" :disable-clear="disableClear" @ok="ok" @clear="clear" />
      </template>
      <template v-slot:append-item>
        <slot name="append-item" />
      </template>
    </v-autocomplete>
    <div v-if="checkboxGroupData">
      <v-subheader>{{ checkboxGroupData.title }}</v-subheader>
      <v-list>
        <v-list-item v-for="item in checkboxGroupData.checkboxes" :key="item.id">
          <v-checkbox
            v-model="selectedCheckboxes"
            :value="item.value"
            :label="item.name"
            dense
            @change="handleCheckbox"
          ></v-checkbox>
        </v-list-item>
      </v-list>
    </div>
  </v-col>
</template>

<script>
import { isArraysContentEqual } from '@/helpers/arraysContentEqual'

import OkClear from './OkClear.vue'

export default {
  name: 'LogsFilter',

  components: { OkClear },

  emits: ['ok', 'clear', 'select', 'input', 'checkbox'],

  props: {
    values: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      required: true,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
    itemText: {
      type: String,
      default: 'label',
    },
    itemValue: {
      type: String,
      default: 'value',
    },
    checkboxGroupData: {
      type: Object,
      default: () => null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    noFilter: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      items: [],
      selected: [],
      selectedCheckboxes: [],
      applied: [],
      appliedCheckboxes: [],
      inputTimeout: null,
      inputText: null,
      inputSaved: '',
    }
  },

  created() {
    this.initialize()
  },

  watch: {
    options(val) {
      this.items = this.selected.length ? [...this.selected, ...val] : val
    },

    values(val) {
      this.selected = val
    },
  },

  computed: {
    disableOk() {
      return (
        this.disabled ||
        (isArraysContentEqual(this.selected, this.applied) &&
          isArraysContentEqual(this.selectedCheckboxes.flat(), this.appliedCheckboxes))
      )
    },

    disableClear() {
      return this.disabled || (!this.applied.length && !this.appliedCheckboxes.length)
    },
  },

  methods: {
    initialize() {
      this.items = [...this.options]
      this.selected = [...this.values]
      if (this.selected.length) {
        this.select()
        this.applied = [...this.selected]
      }
    },

    select() {
      this.inputText = ''
      this.$emit('select', this.selected)
    },

    closeChip(index) {
      this.selected.splice(index, 1)
      this.inputText = this.selected.length ? '' : this.inputSaved
    },

    ok() {
      this.applied = [...this.selected]
      this.appliedCheckboxes = [...this.selectedCheckboxes.flat()]
      this.$emit('ok', this.selected)
    },

    clear() {
      this.selected = []
      this.selectedCheckboxes = []
      this.applied = []
      this.appliedCheckboxes = []
      this.inputText = null
      this.inputSaved = null
      this.$emit('clear')
    },

    handleKeyUp(event) {
      const { value } = event?.target || ''
      this.inputSaved = value
      if (this.inputTimeout) {
        clearTimeout(this.inputTimeout)
        this.inputTimeout = null
      }
      this.$emit('input', value)
      if (value.length > 2) {
        this.inputTimeout = setTimeout(() => this.$emit('input', value), 500)
      }
    },

    handleCheckbox(values) {
      this.$emit('checkbox', values.flat())
    },
  },
}
</script>

<style scoped lang="scss">
.chip-text {
  overflow: hidden;
  text-overflow: ellipsis;
}
.v-chip {
  padding: 0 2px 0 8px;
}
</style>
