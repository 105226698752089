export default {
  data: () => ({}),

  methods: {
    setSelectedBalanceHolders(val) {
      this.selectedBalanceHolders = val
      let selectedArr = []
      if (val.length) selectedArr = this.servicePoints.map((item) => item.value)
      this.setSelectedServicePoints(selectedArr)
      this.servicePointsFilterKey++
      this.isServicePointsFilterApplied = false
    },
    setSelectedServicePoints(val) {
      this.selectedServicePoints = val
      let selectedArr = []
      if (val.length) selectedArr = this.terminals.map((item) => item.value)
      this.setSelectedTerminals(selectedArr)
      this.terminalsFilterKey++
      this.isTerminalsFilterApplied = false
    },
    setSelectedTerminals(val) {
      this.selectedTerminals = val
      let selectedArr = []
      if (val.length) selectedArr = this.devices.map((item) => item.value)
      this.setSelectedDevices(selectedArr)
      this.devicesFilterKey++
      this.isDevicesFilterApplied = false
    },
    setSelectedDevicesType(val) {
      this.selectedDevicesTypes = val
    },
    setSelectedDevices(val) {
      this.selectedDevices = val
    },
  },
}
