<template>
  <div class="d-inline-flex flex-row">
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-icon :class="{ 'icon-disabled': disableOk, 'icon-enabled': !disableOk }" v-on="on" v-bind="attrs" @click="okClick"
          >mdi-check</v-icon
        >
      </template>
      Застосувати
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          :class="{ 'icon-disabled': disableClear, 'icon-enabled': !disableClear }"
          v-on="on"
          v-bind="attrs"
          @click="clearClick"
          >mdi-close</v-icon
        >
      </template>
      Очистити
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: 'OkClear',

  emits: ['ok', 'clear'],

  props: {
    disableOk: {
      type: Boolean,
      default: false,
    },
    disableClear: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    okClick() {
      if (!this.disableOk) {
        this.$emit('ok')
      }
    },

    clearClick() {
      if (!this.disableClear) {
        this.$emit('clear')
      }
    },
  },
}
</script>

<style scoped>
.icon-disabled {
  color: #aaaaaa;
  cursor: text;
}

.icon-enabled {
  color: rgba(6, 52, 103, 0.796);
  cursor: pointer;
}
</style>
